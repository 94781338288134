import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
// import SettingsDrawer from './components/SettingsDrawer';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import dataVault from './lib/DataVault';
// import SettingsDrawer from './components/SettingsDrawer';

import './App.css';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const [error, setError] = useState<string | null>(null);

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
    dataVault.init().catch((err) => { console.error(err); setError(err.message); });
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const closeError = () => {
    setError(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {/* <SettingsDrawer /> */}
        {content}
        <Snackbar
          open={error !== null}
          autoHideDuration={6000}
          onClose={closeError}
        >
          <Alert
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
