import type { FC } from 'react';
import { ItemStatus } from 'src/contract-types/Store';
import Label from 'src/components/Label';
import { IconButton, Tooltip, Zoom } from '@mui/material';

interface Props {
  entity: 'buyer' | 'seller',
  status: ItemStatus,
  prefix?: string,
  suffix?: string,
}

const ItemStatusComponent: FC<Props> = ({ prefix, suffix, status, entity }: Props) => {
  const map = {
    delisted: {
      color: 'error',
      text: 'Delisted',
    },
    verified: {
      color: 'success',
      text: 'Verified',
    },
    unchecked: {
      color: 'primary',
      text: 'Unchecked',
    },
    in_dispute: {
      color: 'warning',
      text: 'Pending Verification',
    },
    in_dispute_request: {
      color: 'warning',
      text: 'Pending Verification',
    },
  };

  const tooltipMap = {
    buyer: {
      delisted: 'The item was delisted and is no longer available',
      verified: 'The item is successfully verified by the curators and fully matches the stated description',
      unchecked: 'The item was not checked by the curators. In case you find out it does not match the description you can always dispute it.',
      in_dispute: 'The item is in dispute state. You should wait for it to be settled by the curators.',
      in_dispute_request: 'The item is in dispute state. You should wait for it to be settled by the curators.',
    },
    seller: {
      delisted: 'The item was delisted and is no longer available. Either it was inappropriate, or did not match it\'s description.',
      verified: 'The item is successfully verified by the curators and fully matches the stated description',
      unchecked: 'The item was not checked by the curators. You can request manual verification in exchange for your collateral.',
      in_dispute: 'The item is in dispute state. You should wait for it to be settled by the curators.',
      in_dispute_request: 'The item is in dispute state. You should wait for it to be settled by the curators.',
    },
  };

  const { text, color }: any = map[status];

  return (
    <Tooltip
      title={tooltipMap[entity][status]}
      color={color}
      arrow
      placement="bottom-start"
      TransitionComponent={Zoom}
      sx={{ margin: 0, padding: 0 }}
    >
      <IconButton
        disableFocusRipple
        disableRipple
      >
        <Label
          sx={{ ml: 2 }}
          color={color}
        >
          {prefix}
          {text}
          {suffix}
        </Label>
      </IconButton>
    </Tooltip>
  );
};

export default ItemStatusComponent;
