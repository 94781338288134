import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import type { Theme } from '@material-ui/core';
import { Avatar, Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import CashIcon from '../../icons/Cash';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import UploadIcon from 'src/icons/Upload';
import Mail from 'src/icons/Mail';
import dataVault from 'src/lib/DataVault';
import { shortenAddress } from 'src/lib/Utils';
import useMounted from 'src/hooks/useMounted';
// @ts-ignore
import { Coin } from 'secretjs';
import Archive from 'src/icons/Archive';
import AcademicCap from 'src/icons/AcademicCap';
import { coinToValue, nominalAmount } from 'src/lib/Value';
import ValueTokens from 'src/lib/ValueTokens';
import InformationCircle from 'src/icons/InformationCircle';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const SECTIONS = [
  {
    title: '',
    items: [
      {
        title: 'Home',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'Purchases',
        path: '/purchases',
        icon: <ShoppingCartIcon fontSize="small" />
      },
      {
        title: 'Sold Items',
        path: '/sold',
        icon: <CashIcon fontSize="small" />
      },
      {
        title: 'Inventory',
        path: '/items',
        icon: <Archive fontSize="small" />
      },
      {
        title: 'Upload',
        path: '/upload',
        icon: <UploadIcon fontSize="small" />
      },
      {
        title: 'Staking',
        path: '/staking',
        icon: <CashIcon fontSize="small" />
      },
      {
        title: 'Contact',
        path: '/contact',
        icon: <Mail fontSize="small" />
      },
    ]
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mounted = useMounted();
  const [address, setAddress] = useState<string | null>(null);
  const [balance, setBalance] = useState<Coin | null>(null);
  const [sections, setSections] = useState<typeof SECTIONS>(SECTIONS);

  const getWallet = useCallback(async () => {
    await dataVault.waitForConnection();
    if (dataVault.getWallet()) {
      const wallet = dataVault.getWallet();
      setAddress(shortenAddress(wallet.address));
      setBalance(wallet.balance && wallet.balance[0] ? wallet.balance[0] : null);
    }
    // Curators & DMCA
    if (dataVault.isCurator()) {
      SECTIONS[0].items.splice(sections.findIndex(({ title }) => title === 'Contact'), 0, {
        title: 'Disputes',
        path: '/disputes',
        icon: <AcademicCap fontSize="small" />
      });
      setSections([...SECTIONS]);
    }
    // --------
    // Admins
    if (dataVault.isAdmin()) {
      SECTIONS[0].items.splice(sections.findIndex(({ title }) => title === 'Contact'), 0, {
        title: 'Statistics',
        path: '/statistics',
        icon: <InformationCircle fontSize="small" />
      });
      setSections([...SECTIONS]);
    }
    // --------
  }, [mounted]);

  useEffect(() => {
    getWallet();
  }, [getWallet]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Avatar
              src="/static/icons/scrt.png"
              sx={{
                height: 48,
                width: 48
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {address}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {balance
                  && (
                    <>
                      {nominalAmount(coinToValue(balance))}
                      {' '}
                      {ValueTokens[balance?.denom].name}
                    </>
                  )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
            opacity: '0.9'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
