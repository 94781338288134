import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import { Identifier } from 'src/contract-types/Store';
import { Address } from 'src/contract-types/Messages';

interface Props {
  disputes: [Address, Identifier][] | null,
  refresh: () => void,
}

type Sort =
  | 'updatedAt|desc'
  | 'updatedAt|asc'
  | 'orders|desc'
  | 'orders|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'Last update (newest)',
    value: 'updatedAt|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'updatedAt|asc'
  },
  {
    label: 'Total orders (highest)',
    value: 'orders|desc'
  },
  {
    label: 'Total orders (lowest)',
    value: 'orders|asc'
  }
];

const applyPagination = (
  disputes: [Address, Identifier][],
  page: number,
  limit: number
): [Address, Identifier][] => disputes?.slice(page * limit, page * limit + limit);

const descendingComparator = (
  a: [Address, Identifier],
  b: [Address, Identifier],
  orderBy: string
): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) => (
  order === 'desc'
    ? (a: [Address, Identifier], b: [Address, Identifier]) => descendingComparator(a, b, orderBy)
    : (a: [Address, Identifier], b: [Address, Identifier]) => -descendingComparator(a, b, orderBy)
);

const applySort = (disputes: [Address, Identifier][], sort: Sort): [Address, Identifier][] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = disputes?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const DisputeTxs: FC<Props> = ({ disputes, refresh, ...other }: Props) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const closeError = () => {
    setError(null);
  };
  const closeSuccess = () => {
    setSuccess(null);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const sortedDisputes = applySort(disputes, sort);
  const paginatedDisputes = applyPagination(sortedDisputes, page, limit);

  if (!disputes) {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <>
      <Card {...other}>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
              p={2}
            >
              Disputes
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        >
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder="Search transactions"
              value={query}
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              m: 1,
              width: 240
            }}
          >
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Item
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedDisputes.map(([buyer, identifier]) => (
                  <TableRow
                    hover
                    key={identifier.id}
                  >
                    <TableCell>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/item/${identifier.filesystem}/${identifier.id}?b=${buyer}`}
                        variant="subtitle2"
                      >
                        {identifier.filesystem}
                        &nbsp;
                        {identifier.id}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={disputes.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <Snackbar
        open={error !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeSuccess}
      >
        <Alert
          severity="success"
          sx={{ width: '100%' }}
        >
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};

DisputeTxs.propTypes = {
  disputes: PropTypes.array.isRequired
};

export default DisputeTxs;
