import { FC } from 'react';
import { Box } from '@material-ui/core';

const Loading: FC = () => (
  <Box m={1}>
    <img
      height={32}
      src="/static/loading.gif"
      alt="Loading..."
    />
  </Box>
);

export default Loading;
