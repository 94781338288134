import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Container, Snackbar } from '@material-ui/core';
import { customerApi } from '../__fakeApi__/customerApi';
import useMounted from '../hooks/useMounted';
import useSettings from '../hooks/useSettings';
import gtm from '../lib/gtm';
import { Identifier } from 'src/contract-types/Store';
import DisputeTxs from 'src/components/dashboard/customer/DisputesTxs';
import { Address } from 'src/contract-types/Messages';

const Disputes: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [disputes, setDisputes] = useState<[Address, Identifier][]>([]);
  const [error, setError] = useState<string | null>(null);

  const closeError = () => {
    setError(null);
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getDisputes = useCallback(async () => {
    try {
      const data = await customerApi.curatorsGetDisputes();

      if (mounted.current) {
        setDisputes(data);
      }
    } catch (err) {
      if (mounted.current) {
        console.error(err);
        setError(err.message);
      }
    }
  }, [mounted]);

  useEffect(() => {
    getDisputes();
  }, [getDisputes]);

  const refresh = () => {
    setDisputes(null);
    getDisputes();
  };

  return (
    <>
      <Helmet>
        <title>SecretBazaar | Disputes</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 8,
          opacity: '0.98'
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Box sx={{ mt: 3 }}>
            <DisputeTxs
              disputes={disputes}
              refresh={refresh}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={error !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Disputes;
