import React, { FC } from 'react';

interface Props {
  text: string,
  highlight: string,
}

const Highlighted: FC<Props> = ({ text = '', highlight = '' }: Props) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => (regex.test(part) ? (
        <mark key={i}>{part}</mark>
      ) : (
        <span key={i}>{part}</span>
      )))}
    </span>
  );
};
export default Highlighted;
