import moment from 'moment';
import { Uint128, ValueInfo } from 'src/contract-types/Value';
import { Readable } from 'stream';
import toArray from 'stream-to-array';
import ValueTokens from './ValueTokens';
import millify from 'millify';

export const getAssets = (): ValueInfo[] => {
  const assets: ValueInfo[] = [];
  Object.keys(ValueTokens).forEach((key) => {
    if (key.startsWith('secret1')) {
      assets.push({
        token: {
          contract_addr: key,
        }
      });
    } else {
      assets.push({
        native_token: {
          denom: key,
        }
      });
    }
  });
  return assets;
};

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const shortenHash = (hash: string): string => `${hash.slice(0, 5)}...${hash.slice(-5)}`;
export const shortenAddress = (hash: string): string => `${hash.slice(0, 8)}...${hash.slice(-5)}`;

export const formatDate = (timestamp: Uint128) => moment(Number(timestamp) / 1000000).format('YYYY/MM/DD HH:mm');

export const itemRating = (cumulative_rating: string, rated: number): string => (rated > 0 ? `${((Number(cumulative_rating) * 100) / rated).toFixed(2)}%` : '---');

export const score = (s: number): string => millify(s / 10 ** 6);

export const streamToBuffer = async (stream: Readable): Promise<Buffer> => {
  const parts = await toArray(stream);
  const buffers = [];
  for (let i = 0, l = parts.length; i < l; ++i) {
    buffers.push((parts[i] instanceof Buffer) ? parts[i] : Buffer.from(parts[i]));
  }
  return Buffer.concat(buffers);
};

export const bufferToReadable = (buffer: Buffer): Readable => {
  const readable = new Readable();
  // eslint-disable-next-line no-underscore-dangle
  readable._read = () => {
  }; // _read is required but you can noop it
  readable.push(buffer);
  readable.push(null);
  return readable;
};

export const currentEpoch = (epochSize: number, currentBlock: number): number => Math.floor(currentBlock / epochSize);

export const getFileExtension = (str) => ((/[.]/.exec(str)) ? /[^.]+$/.exec(str)[0] : undefined);

export const calculatePriceFromBalances = (secondBalance: number, balanceNeeded: number): string => `${(secondBalance / balanceNeeded).toFixed(2)}$`;
