import type { ChangeEvent, FC, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import { getTokenIcon, nominalAmount, valuesToNamed } from 'src/lib/Value';
import Star from 'src/icons/Star';
import Minus from 'src/icons/Minus';
import ShoppingCart from 'src/icons/ShoppingCart';
import Check from 'src/icons/Check';
import dataVault from '../../../lib/DataVault';
import { FileSystem, Identifier, ItemDescription } from '../../../contract-types/Store';
import ItemStatus from './ItemStatus';
import { itemRating } from 'src/lib/Utils';
import Loading from '../../../icons/Loading';
import Highlighted from '../../Highlighted';
import jp from 'jsonpath';
import { customerApi } from '../../../__fakeApi__/customerApi';
import useMounted from '../../../hooks/useMounted';
import X from 'src/icons/X';
import { IconButton, Tooltip, Zoom } from '@mui/material';

interface SortOption {
  field: string;
  label: string;
  desc: boolean;
}

const PAGE_SIZES = [50, 100, 200, 500];

const sortOptions: SortOption[] = [
  {
    label: 'Name (A-Z)',
    field: 'name',
    desc: false,
  },
  {
    label: 'Name (Z-A)',
    field: 'name',
    desc: true,
  },
  {
    label: 'Price (highest first)',
    field: '$.price.amount',
    desc: true,
  },
  {
    label: 'Price (lowest first)',
    field: '$.price.amount',
    desc: false,
  },
  {
    label: 'Rating (highest first)',
    field: '$.rating',
    desc: true,
  },
  {
    label: 'Rating (lowest first)',
    field: '$.rating',
    desc: false,
  },
  {
    label: 'Rated by (highest first)',
    field: '$.rated',
    desc: true,
  },
  {
    label: 'Rated by (lowest first)',
    field: '$.rated',
    desc: false,
  },
  {
    label: 'Status',
    field: '$.status',
    desc: false,
  },
];

const tabs = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'Verified',
    value: 'verified'
  },
  {
    label: 'Unchecked',
    value: 'unchecked'
  },
  {
    label: 'Pending Verification',
    value: 'pending_verification'
  }
];

const applyFilters = (
  items: [Identifier, ItemDescription][],
  currentTab: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  query: string,
): [Identifier, ItemDescription][] => items?.filter(([, item]) => {
  let pass = (currentTab === 'All') ? true : item.status === currentTab;
  if ((currentTab === 'pending_verification') && (item.status === 'in_dispute' || item.status === 'in_dispute_request')) {
    pass = true;
  }
  if (pass && query.trim() !== '') {
    pass = item.meta.find(([key]) => key === 'title')[1].toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }
  return pass;
});

const applySort = (items: [Identifier, ItemDescription][], sort: SortOption): [Identifier, ItemDescription][] => items?.sort(([, a], [, b]) => {
  let diff;
  switch (sort.field) {
    case 'name':
      // @ts-ignore
      diff = a.meta.find(([key]) => key === 'title')[1].localeCompare(b.meta.find(([key]) => key === 'title')[1]);
      break;
    default:
      const x = jp.query(a, sort.field)[0];
      const y = jp.query(b, sort.field)[0];
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      diff = (!isNaN(x) && !isNaN(y)) ? x - y : (x as string).localeCompare(y);
      break;
  }

  return sort.desc ? -diff : diff;
});

const Inventory: FC = () => {
  const mounted = useMounted();
  const [currentTab, setCurrentTab] = useState<string>('All');
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(PAGE_SIZES[2]);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<SortOption>(sortOptions[0]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [items, setItems] = useState<[Identifier, ItemDescription][] | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogOk, setDialogOk] = useState<() => void>(null);

  const getSellerItemsCB = useCallback(async (_page: number, _limit: number) => {
    try {
      const data = await customerApi.getSellerItems(_page, _limit);

      if (mounted.current) {
        setItems(data.StoreGetSellerItems.items);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSellerItemsCB(page, limit);
  }, [getSellerItemsCB]);

  const closeError = () => {
    setError(null);
  };
  const closeSuccess = () => {
    setSuccess(null);
  };
  const popupDialog = (title: string, description: string, cb: () => void) => {
    setDialogOpen(true);
    setDialogTitle(title);
    setDialogDescription(description);
    setDialogOk(() => cb);
  };

  const requestVerification = async (cid: string, filesystem: FileSystem) => {
    try {
      await dataVault.storeRequestVerification(cid, filesystem);
      setSuccess('Successfully approved transaction');
      setItems(null);
      getSellerItemsCB(page, limit);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  const delist = async (cid: string, filesystem: FileSystem) => {
    try {
      await dataVault.storeDelistItem(cid, filesystem);
      setSuccess('Successfully approved transaction');
      setItems(null);
      getSellerItemsCB(page, limit);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  };

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(sortOptions[event.target.value]);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setItems(null);
    getSellerItemsCB(newPage, limit);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setItems(null);
    getSellerItemsCB(page, newLimit);
  };

  return (
    <>
      <Card>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid
            xs={11}
            item
          >
            <Typography
              color="textPrimary"
              variant="h5"
              padding={2}
            >
              Inventory
            </Typography>
          </Grid>
          <Grid item>
            {!items
              && <Loading />}
          </Grid>
          <Grid
            xs={12}
            item
          >
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Divider />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                m: -1,
                p: 2
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: '100%',
                  width: 500
                }}
              >
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search transactions"
                  value={query}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  m: 1,
                  width: 240
                }}
              >
                <TextField
                  label="Sort By"
                  name="sort"
                  onChange={handleSortChange}
                  select
                  SelectProps={{ native: true }}
                  value={sortOptions.indexOf(sort)}
                  variant="outlined"
                >
                  {sortOptions.map((option, index) => (
                    <option
                      key={index}
                      value={index}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Scrollbar style={{ height: 'inherit' }}>
              <Box sx={{ minWidth: 700 }}>
                <Table>
                  {items?.length === 0
                    && (
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                        align="center"
                      >
                        No items yet..
                      </Typography>
                    )}
                  {items?.length > 0
                    && (
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Name
                          </TableCell>
                          <TableCell>
                            Price
                          </TableCell>
                          <TableCell align="center">
                            Rating
                          </TableCell>
                          <TableCell align="center">
                            Rated By
                          </TableCell>
                          <TableCell align="center">
                            Status
                          </TableCell>
                          <TableCell align="center">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    )}
                  <TableBody>
                    {applySort(applyFilters(items, currentTab, query), sort)?.map(([identifier, item]) => {
                      const namedValue = valuesToNamed(item.price);

                      return (
                        <TableRow
                          hover
                          key={`${identifier.filesystem}/${identifier.id}`}
                        >
                          <TableCell>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/item/${identifier.filesystem}/${identifier.id}`}
                              variant="subtitle2"
                            >
                              <Highlighted
                                text={item.meta.find(([key]) => key === 'title')[1]}
                                highlight={query}
                              />
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex'
                              }}
                            >
                              <Avatar
                                src={getTokenIcon(namedValue)}
                                sx={{
                                  height: 42,
                                  width: 42
                                }}
                              >
                                {namedValue.name}
                              </Avatar>
                              <Box sx={{ ml: 1 }}>
                                <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                                >
                                  {nominalAmount(namedValue.value)}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  variant="body2"
                                >
                                  {namedValue.name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Star />
                            <Typography
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {itemRating(item.cumulative_rating, item.rated)}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <ShoppingCart />
                            <Typography
                              color="textPrimary"
                              variant="subtitle2"
                            >
                              {item.rated}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <ItemStatus
                              entity="seller"
                              status={item.status}
                            />
                          </TableCell>
                          {(() => {
                            if (item.status === 'unchecked' && item.disputes_disabled === false) {
                              return (
                                <TableCell
                                  align="center"
                                >
                                  <Tooltip
                                    title="Request to verify the content in exchange for your collateral. This will mark the item's status as verified and drastically increase trust from potential buyers."
                                    color="primary"
                                    arrow
                                    placement="bottom-start"
                                    TransitionComponent={Zoom}
                                    sx={{ margin: 0, padding: 0 }}
                                  >
                                    <IconButton
                                      disableFocusRipple
                                      disableRipple
                                    >
                                      <Button
                                        color="primary"
                                        endIcon={<Check />}
                                        size="small"
                                        variant="contained"
                                        onClick={() => popupDialog('Request Verification', 'Caution! The manual verification will cost you the item\'s collateral despite the result of the verification.', () => requestVerification(identifier.id, identifier.filesystem))}
                                      >
                                        Request Verification
                                      </Button>
                                    </IconButton>
                                  </Tooltip>
                                  &nbsp;&nbsp;&nbsp;
                                  <Tooltip
                                    title="Removes the item from the protocol"
                                    color="primary"
                                    arrow
                                    placement="bottom-start"
                                    TransitionComponent={Zoom}
                                    sx={{ margin: 0, padding: 0 }}
                                  >
                                    <IconButton
                                      disableFocusRipple
                                      disableRipple
                                    >
                                      <Button
                                        color="secondary"
                                        endIcon={<X />}
                                        size="small"
                                        variant="contained"
                                        onClick={() => popupDialog('Delist Item', 'Caution! This action removes the item from the protocol, this cannot be undone!', () => delist(identifier.id, identifier.filesystem))}
                                      >
                                        Delist
                                      </Button>
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              );
                            }
                            return (<TableCell align="center"><Minus fontSize="small" /></TableCell>);
                          }
                          )()}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
            <TablePagination
              component="div"
              count={-1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={PAGE_SIZES}
            />
          </Grid>
        </Grid>
      </Card>
      <Snackbar
        open={error !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeSuccess}
      >
        <Alert
          severity="success"
          sx={{ width: '100%' }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Dialog
        open={dialogOpen}
        keepMounted
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Disagree</Button>
          <Button onClick={() => {
            setDialogOpen(false);
            dialogOk();
          }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Inventory;
