import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { customerApi } from '../__fakeApi__/customerApi';
import useMounted from '../hooks/useMounted';
import useSettings from '../hooks/useSettings';
import gtm from '../lib/gtm';
import Check from 'src/icons/Check';
import Loading from 'src/icons/Loading';
import AcademicCap from 'src/icons/AcademicCap';
import Archive from 'src/icons/Archive';
import Ban from 'src/icons/Ban';
import Cash from 'src/icons/Cash';
import Clock from 'src/icons/Clock';
import Refresh from 'src/icons/Refresh';
import StarIcon from 'src/icons/Star';
import { Box, Container, Grid, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography, Snackbar, Alert, Link, Card } from '@material-ui/core';
import { AdminStoreStats } from 'src/contract-types/Store';
import { TxStats } from 'src/contract-types/Transactions';
import InformationCircle from 'src/icons/InformationCircle';
import User from 'src/icons/User';
import ShoppingCart from 'src/icons/ShoppingCart';

const Disputes: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [storeStats, setStoreStats] = useState<AdminStoreStats>(null);
  const [txStats, setTxStats] = useState<TxStats>(null);
  const [error, setError] = useState<string | null>(null);

  const closeError = () => {
    setError(null);
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getStatistics = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const storeStats = await customerApi.adminGetStoreStats();
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const txStats = await customerApi.adminGetTxStats();

      if (mounted.current) {
        setStoreStats(storeStats);
        setTxStats(txStats);
      }
    } catch (err) {
      if (mounted.current) {
        console.error(err);
        setError(err.message);
      }
    }
  }, [mounted]);

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  return (
    <>
      <Helmet>
        <title>SecretBazaar | Statistics</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 8,
          opacity: '0.98'
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Box sx={{ mt: 3 }}>
            <Grid
              alignItems="flex-start"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
              sx={{ mb: 3 }}
            >
              <Grid
                item
                xs={12}
              >
                <Card>
                  <Box sx={{ display: 'flex' }}>
                    <Box
                      sx={{
                        p: 3,
                        flexGrow: 1,
                        minWidth: 150,
                      }}
                    >
                      <Typography
                        align="center"
                        color="textPrimary"
                        variant="h5"
                      >
                        <InformationCircle />
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        component="h4"
                        variant="overline"
                      >
                        Statistics
                      </Typography>
                    </Box>
                    {(!storeStats || !txStats)
                      && (
                        <Box style={{ position: 'absolute', right: 26 }}>
                          <Loading />
                        </Box>
                      )}
                  </Box>
                  <Divider />
                  {(storeStats && txStats)
                    && (
                      <List disablePadding>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem
                              divider
                            >
                              <ListItemAvatar>
                                <Cash />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    { /* TODO: Take decimals from the config */}
                                    {storeStats?.stats.total_sells
                                      && `$ ${(Number(storeStats?.stats.total_sells) / 10 ** 6).toFixed(2)}`}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Total Sells
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem
                              divider
                            >
                              <ListItemAvatar>
                                <Clock />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {txStats?.pending_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Pending Transactions
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            textAlign="right"
                          >
                            <ListItem
                              divider
                            >
                              <ListItemAvatar>
                                <Cash />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {storeStats?.stats.items_sold}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Sold
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem divider>
                              <ListItemAvatar>
                                <Check />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {txStats?.approved_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Approved Transactions
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem
                              divider
                            >
                              <ListItemAvatar>
                                <Archive />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {storeStats?.stats.items_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Items in Inventory
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem divider>
                              <ListItemAvatar>
                                <StarIcon />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {txStats?.complete_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Complete Transactions
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem divider>
                              <ListItemAvatar>
                                <Ban />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {storeStats?.stats.dmca_delisted_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Inappropriate
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem
                              divider
                            >
                              <ListItemAvatar>
                                <AcademicCap />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {txStats?.disputed_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    In-dispute Transactions
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem divider>
                              <ListItemAvatar>
                                <User />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    { /* TODO: Remove the default value once a new contracts release is deployed */}
                                    {storeStats?.sellers_stats?.count || 0}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Unique Sellers
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem divider>
                              <ListItemAvatar>
                                <Refresh />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    {txStats?.refunded_count}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Refunded Transactions
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <ListItem>
                              <ListItemAvatar>
                                <ShoppingCart />
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography
                                primary={(
                                  <Link
                                    color="textPrimary"
                                    sx={{ cursor: 'pointer' }}
                                    underline="none"
                                    variant="subtitle2"
                                  >
                                    { /* TODO: Remove the default value once a new contracts release is deployed */}
                                    {storeStats?.buyers_stats?.count || 0}
                                  </Link>
                                )}
                                secondary={(
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    Unique Buyers
                                  </Typography>
                                )}
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                      </List>
                    )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={error !== null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={closeError}
      >
        <Alert
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Disputes;
