// @ts-ignore
import { Account, Coin, StdFee, SigningCosmWasmClient } from 'secretjs';
import { NativeToken, Token, Uint128, Value } from 'src/contract-types/Value';
import {
  Address,
  AdminGetStoreStatsAnswer,
  AdminGetTxStatsAnswer,
  BalanceGetAnswer,
  BalanceGetPendingAnswer,
  ConfigInfoGetAnswer,
  ContentManagerGetAnswer,
  CuratorsGetDisputeRequestsAnswer,
  CuratorsGetDisputesAnswer,
  CuratorsGetItemAnswer,
  GetPersonalStatsAnswer,
  HandleMsg,
  QueryMsg,
  StakeHandleMsg,
  StoreDescribeItemAnswer,
  StoreGetBuyerTxAnswer,
  StoreGetBuyerTxsAnswer,
  StoreGetItemAnswer,
  StoreGetSellerItemsAnswer,
  StoreGetSellerTxsAnswer,
  StoreGetStatsAnswer,
  TxGetBuyerStatsAnswer,
  TxGetSellerStatsAnswer
} from '../contract-types/Messages';
import * as Staking from '../contract-types/StakingMessages';
import { FileSystem, Identifier, ItemDescription, Keys, LimitedAccess } from '../contract-types/Store';
import { getValueDecimals, getValueName, isNativeToken } from './Value';
import { ValueInfo } from '../contract-types/Value';
import { calculatePriceFromBalances, getAssets } from './Utils';
import ValueTokens from './ValueTokens';

declare global {
  interface Window {
    keplr: any,
    getOfflineSigner: any,
    getEnigmaUtils: any,
  }
}

const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export class DataVault {
  private readonly DEVELOPMENT_MODE = true; // TODO: change to false

  private readonly SIG_VER = '0.0.17';

  private readonly CHAIN_ID: string = '';

  private readonly CHAIN_NAME: string = '';

  private readonly KEPLR_CHAIN_RPC: string = ''; // For testnets

  private readonly KEPLR_CHAIN_LCD: string = '';

  private readonly CHAIN_LCD: string = '';

  private readonly DATAVAULT: string = '';

  private readonly DATAVAULT_HASH: string = '';

  private readonly STAKING_CONTRACT: string = '';

  private readonly STAKING_CONTRACT_HASH: string = '';

  private readonly CONTENT_MANAGER: string = '';

  private readonly PRICING_ORACLE: string = '';

  private readonly PRICING_ORACLE_HASH: string = '';

  private readonly EXCHANGE: string = '';

  private readonly EXCHANGE_CODE_HASH: string = '';

  public readonly COIN: string = 'SCRT';

  public readonly DENOM: string = 'uscrt';

  public readonly BASE_ASSET: string = 'SUSDC';

  public readonly DAVA_DECIMALS: number = 6;

  public readonly DAVA_TOKEN: Address = '';

  public readonly xDAVA_TOKEN: Address = '';

  private readonly AVG_GAS_PRICE = 0.25;

  public ASSETS: ValueInfo[] = getAssets();

  private readonly PRICING_ORACLE_RATE_FRACTIONAL = Number(1000000000000000000);

  private oracleRates: Map<string, number> = new Map();

  private secretjs: SigningCosmWasmClient | undefined;

  private defaultAccount: Account | undefined;

  private davaPrice: string = '';

  private connected: boolean = false;

  private _config: ConfigInfoGetAnswer;

  public get config() { return this._config; }

  constructor() {
    switch (process.env.NODE_ENV) {
      case 'development':
        this.CHAIN_ID = 'pulsar-2';
        this.CHAIN_NAME = 'Pulsar-2';
        this.KEPLR_CHAIN_RPC = 'https://rpc.pulsar.scrttestnet.com:443/'; // For testnets
        this.KEPLR_CHAIN_LCD = 'https://api.pulsar.scrttestnet.com/';
        this.CHAIN_LCD = 'https://api.pulsar.scrttestnet.com/';
        // this.CHAIN_LCD = 'https://testnet-lcd.node.secretbazaar.workers.dev:443/';
        this.DATAVAULT = 'secret1pzkx8ny7xnep57yn6gsl4hcfqglmvjqqtumrkj';
        this.DATAVAULT_HASH = '89a4c6b277cfcc1ba82154fa189f5a8a22396a2e8aee129f579fc70a3ce2d88a';
        this.DAVA_TOKEN = 'secret18xt4pkg9ruc44lh73tmuu0lex9qmp26dl0mfj9';
        this.xDAVA_TOKEN = 'secret1xx5mst7pxzzkkvmzcgxdxpszqzxffzhg58hxxd';
        this.STAKING_CONTRACT = 'secret19tvy2w5n4dlagua3lzupds48cems7k4xaml38s';
        this.STAKING_CONTRACT_HASH = '57d128e598d992c740037108f0df96d9112631ac1e2028152d8f8a05c28e26d3';
        this.CONTENT_MANAGER = null;
        this.PRICING_ORACLE = 'secret1fmnlsfek74jzrag5caq86qlear9zlrv4gsc8nz'; // TODO: pulsar deployment
        this.PRICING_ORACLE_HASH = 'dfc500098c5e04716d98e4b0fac2b5187b04daa7c5b17c5fb318760d743d14e2';
        this.EXCHANGE = 'secret1ajpawhllfcw224jfxmqnnmrkwdxedvf9ag7769';
        this.EXCHANGE_CODE_HASH = 'ea6bfaf124b2540765b81d12f3efcd6efe3aeaa4ab498a2331f2e40ad9ea0209';
        break;
      case 'production':
      default:
        if (this.DEVELOPMENT_MODE) {
          // Testnet
          this.CHAIN_ID = 'pulsar-2';
          this.CHAIN_NAME = 'Pulsar-2';
          this.KEPLR_CHAIN_RPC = 'https://rpc.pulsar.scrttestnet.com:443/'; // For testnets
          this.KEPLR_CHAIN_LCD = 'https://api.pulsar.scrttestnet.com/';
          this.CHAIN_LCD = 'https://api.pulsar.scrttestnet.com/';
          // this.CHAIN_LCD = 'https://testnet-lcd.node.secretbazaar.workers.dev:443/';
          this.DATAVAULT = 'secret1pzkx8ny7xnep57yn6gsl4hcfqglmvjqqtumrkj';
          this.DATAVAULT_HASH = '89a4c6b277cfcc1ba82154fa189f5a8a22396a2e8aee129f579fc70a3ce2d88a';
          this.DAVA_TOKEN = 'secret18xt4pkg9ruc44lh73tmuu0lex9qmp26dl0mfj9';
          this.xDAVA_TOKEN = 'secret1xx5mst7pxzzkkvmzcgxdxpszqzxffzhg58hxxd';
          this.STAKING_CONTRACT = 'secret19tvy2w5n4dlagua3lzupds48cems7k4xaml38s';
          this.STAKING_CONTRACT_HASH = '57d128e598d992c740037108f0df96d9112631ac1e2028152d8f8a05c28e26d3';
          this.CONTENT_MANAGER = null;
          this.PRICING_ORACLE = 'secret1fmnlsfek74jzrag5caq86qlear9zlrv4gsc8nz'; // TODO: pulsar deployment
          this.PRICING_ORACLE_HASH = 'dfc500098c5e04716d98e4b0fac2b5187b04daa7c5b17c5fb318760d743d14e2';
          this.EXCHANGE = 'secret1ajpawhllfcw224jfxmqnnmrkwdxedvf9ag7769';
          this.EXCHANGE_CODE_HASH = 'ea6bfaf124b2540765b81d12f3efcd6efe3aeaa4ab498a2331f2e40ad9ea0209';
          break;
        } else {
          // Production
          this.CHAIN_ID = 'secret-4';
          this.CHAIN_NAME = 'Secret-4';
          this.KEPLR_CHAIN_LCD = 'https://node.secretbazaar.workers.dev/'; // TODO: setup proxy
          this.CHAIN_LCD = 'https://node.secretbazaar.workers.dev/'; // TODO: setup proxy
          this.DATAVAULT = 'secret1js7z7evp68pehxazpclydnwj79cxx9c0zt4dgc';
          this.DATAVAULT_HASH = ''; // TODO
          this.DAVA_TOKEN = 'secret18xt4pkg9ruc44lh73tmuu0lex9qmp26dl0mfj9';
          this.xDAVA_TOKEN = '';
          this.STAKING_CONTRACT = '';
          this.STAKING_CONTRACT_HASH = '';
          this.CONTENT_MANAGER = 'secret1lsuhveakhfqycdrfsurjyj7p45s02n9mys86lg'; // TODO
          this.PRICING_ORACLE = 'secret1p0jtg47hhwuwgp4cjpc46m7qq6vyjhdsvy2nph'; // TODO: mainnet deployment
          this.PRICING_ORACLE_HASH = ''; // TODO
          break;
        }
    }
  }

  private static async waitForKeplr() {
    const delay = 10;
    const timeout = 5000;
    let timeoutCounter = 0;
    // Wait for Keplr to be injected to the page
    while (
      !window.keplr
      && !window.getOfflineSigner
      && !window.getEnigmaUtils) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(delay);
      // eslint-disable-next-line no-cond-assign
      if ((timeoutCounter += delay) > timeout) {
        throw new Error('Keplr extension not found');
      }
    }
  }

  public async init() {
    await DataVault.waitForKeplr();

    window.addEventListener('keplr_keystorechange', () => {
      window.location.reload();
    });

    if (process.env.NODE_ENV === 'development' || this.DEVELOPMENT_MODE) {
      await window.keplr.experimentalSuggestChain({
        chainId: this.CHAIN_ID,
        chainName: this.CHAIN_NAME,
        rpc: this.KEPLR_CHAIN_RPC,
        rest: this.KEPLR_CHAIN_LCD,
        bip44: {
          coinType: 529,
        },
        coinType: 529,
        stakeCurrency: {
          coinDenom: this.COIN,
          coinMinimalDenom: this.DENOM,
          coinDecimals: ValueTokens[this.DENOM].decimals,
        },
        bech32Config: {
          bech32PrefixAccAddr: 'secret',
          bech32PrefixAccPub: 'secretpub',
          bech32PrefixValAddr: 'secretvaloper',
          bech32PrefixValPub: 'secretvaloperpub',
          bech32PrefixConsAddr: 'secretvalcons',
          bech32PrefixConsPub: 'secretvalconspub',
        },
        currencies: [
          {
            coinDenom: this.COIN,
            coinMinimalDenom: this.DENOM,
            coinDecimals: ValueTokens[this.DENOM].decimals,
          },
        ],
        feeCurrencies: [
          {
            coinDenom: this.COIN,
            coinMinimalDenom: this.DENOM,
            coinDecimals: ValueTokens[this.DENOM].decimals,
          },
        ],
        gasPriceStep: {
          low: 0.1,
          average: this.AVG_GAS_PRICE,
          high: 0.4,
        },
        features: ['secretwasm'],
      });
    }

    await window.keplr.enable(this.CHAIN_ID);

    const keplrOfflineSigner = window.getOfflineSigner(this.CHAIN_ID);
    const accounts = await keplrOfflineSigner.getAccounts();
    if (accounts.length === 0) {
      throw new Error('No Available Accounts in Keplr');
    }
    [this.defaultAccount] = accounts;
    // @ts-ignore
    this.defaultAccount.balance = [{ amount: 0, denom: 'uscrt' }];

    this.secretjs = new SigningCosmWasmClient(
      this.CHAIN_LCD,
      (this.defaultAccount as Account).address,
      keplrOfflineSigner,
      window.getEnigmaUtils(this.CHAIN_ID),
      {
        init: {
          amount: [{ amount: '300000', denom: this.DENOM }],
          gas: '300000',
        },
        exec: {
          amount: [{ amount: '300000', denom: this.DENOM }],
          gas: '300000',
        },
      },
    );
    console.info('Secret Network Initialized: ', this.defaultAccount, this.CHAIN_ID);
    this._config = await this.getConfig();
    await this.updateBalance();

    try {
      await this.getDAVAPrice();
    } catch (e) {
      console.error(e);
    }

    // const contentManager = await this.contentManagerGet();
    // if (contentManager) {
    //   console.info('Content Manager: ', contentManager);
    // }

    await this.createSignature();

    this.connected = true;
  }

  public async waitForConnection() {
    // Wait for Keplr to be injected to the page
    while (
      !this.connected
    ) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(10);
    }
  }

  public isConnected(): boolean {
    return this.connected;
  }

  public getCurrentBlock(): Promise<number> {
    return this.secretjs.getHeight();
  }

  private async execute(handleMsg: HandleMsg, coins: Coin[] = [], fee?: StdFee) {
    const res = await this.secretjs?.execute(this.DATAVAULT, handleMsg, '', coins, fee, this.DATAVAULT_HASH);
    await this.updateBalance();
    return res;
  }

  private async query(queryMsg: QueryMsg) {
    return this.secretjs?.queryContractSmart(this.DATAVAULT, queryMsg, undefined, this.DATAVAULT_HASH);
  }

  private async executeStakingContract(handleMsg: StakeHandleMsg, coins: Coin[] = [], fee?: StdFee) {
    const res = await this.secretjs?.execute(this.STAKING_CONTRACT, handleMsg, '', coins, fee, this.STAKING_CONTRACT_HASH);
    return res;
  }

  private async queryStakingContract(queryMsg: Staking.QueryMsg) {
    return this.secretjs?.queryContractSmart(this.STAKING_CONTRACT, queryMsg, undefined, this.STAKING_CONTRACT_HASH);
  }

  private snip20Send(snip20token: string, handleMsg: HandleMsg, amount: Uint128) {
    // console.log(amount);
    return this.secretjs?.execute(snip20token, {
      send: {
        recipient: this.DATAVAULT,
        amount,
        msg: btoa(JSON.stringify(handleMsg)),
      }
    });
  }

  private snip20SendStaking(snip20token: string, handleMsg: StakeHandleMsg, amount: Uint128) {
    // console.log(amount);
    return this.secretjs?.execute(snip20token, {
      send: {
        recipient: this.STAKING_CONTRACT,
        amount,
        msg: btoa(JSON.stringify(handleMsg)),
      }
    });
  }

  private getSigKey(walletAddress: string) {
    return `sig_v${this.SIG_VER}_${walletAddress}`;
  }

  private async getSignature(permitName: string, allowedTokens: string[], permissions: string[]): Promise<string> {
    let signature = JSON.parse(window.localStorage.getItem(this.getSigKey(this.defaultAccount.address)));
    if (!signature) {
      signature = (await window.keplr.signAmino(
        this.CHAIN_ID,
        this.defaultAccount.address,
        {
          chain_id: this.CHAIN_ID,
          account_number: '0', // Must be 0
          sequence: '0', // Must be 0
          fee: {
            amount: [{ denom: 'uscrt', amount: '0' }], // Must be 0 uscrt
            gas: '1', // Must be 1
          },
          msgs: [
            {
              type: 'query_permit', // Must be "query_permit"
              value: {
                permit_name: permitName,
                allowed_tokens: allowedTokens,
                permissions,
              },
            },
          ],
          memo: '', // Must be empty
        },
        {
          preferNoSetFee: true, // Fee must be 0, so hide it from the user
          preferNoSetMemo: true, // Memo must be empty, so hide it from the user
        }
      )).signature;
      window.localStorage.setItem(this.getSigKey(this.defaultAccount.address), JSON.stringify(signature));
    }

    return signature;
  }

  public async createSignature() {
    const permitName = 'secretbazaar';
    const allowedTokens = [this.DATAVAULT]; // Dicted by design
    const permissions = ['owner'];

    const signature = await this.getSignature(permitName, allowedTokens, permissions);

    return {
      permitName,
      allowedTokens,
      permissions,
      signature,
    };
  }

  private async queryPermit(queryMsg: QueryMsg) {
    const {
      permitName,
      allowedTokens,
      permissions,
      signature,
    } = await this.createSignature();

    return this.secretjs.queryContractSmart(
      this.DATAVAULT,
      {
        with_permit: {
          query: queryMsg,
          permit: {
            params: {
              permit_name: permitName,
              allowed_tokens: allowedTokens,
              chain_id: this.CHAIN_ID,
              permissions,
            },
            signature,
          },
        },
      },
      undefined,
      this.DATAVAULT_HASH
    );
  }

  public getWallet() {
    return this.defaultAccount;
  }

  public getDavaPrice() {
    return this.davaPrice;
  }

  public isCurator(): boolean {
    return (this.config?.ConfigInfoGet.curators.includes(this.defaultAccount.address) || this.config?.ConfigInfoGet.dmca.includes(this.defaultAccount.address));
  }

  public isAdmin(): boolean {
    return this.config?.ConfigInfoGet.admins.includes(this.defaultAccount.address);
  }

  private async updateBalance() {
    if (this.secretjs && this.defaultAccount) {
      try {
        const res = await this.secretjs.getAccount(this.defaultAccount.address);
        if (res) {
          this.defaultAccount = res;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  }

  private async getDAVAPrice() {
    // @ts-ignore
    const answer = await this.secretjs?.queryContractSmart(this.EXCHANGE, 'pair_info', undefined, this.EXCHANGE_CODE_HASH);
    const scrtUSD = await (await fetch('https://api.coingecko.com/api/v3/simple/price?ids=secret&vs_currencies=USD&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })).json();
    // @ts-ignore
    const scrtPrice = scrtUSD.secret.usd;
    console.log('DAVA/SCRT', answer.pair_info.amount_0, '/', answer.pair_info.amount_1); // Debug
    console.log('USD/SCRT', scrtPrice); // Debug
    this.davaPrice = calculatePriceFromBalances(Number(answer.pair_info.amount_1) * scrtPrice, Number(answer.pair_info.amount_0));
  }

  /**
   * Handles
   */

  public async storeAddItem(cid: string, filesystem: FileSystem, keys: Keys, price: Value, disputesDisabled: boolean, limitedSell: Uint128, expireTime: Uint128, limitedAccess: LimitedAccess | null, meta: [string, string][]) {
    let amount = '0';
    const coins: Coin[] = [];
    const msg = {
      store_add_items: {
        items: [{
          identifier: {
            filesystem,
            id: cid,
          },
          price,
          keys,
          limited_sell: limitedSell,
          limited_access: limitedAccess,
          disputes_disabled: disputesDisabled,
          expire_time: expireTime,
          meta,
        }]
      }
    };
    if (!disputesDisabled) {
      amount = (await this.calculateCollateral(price)).toString();
      if (isNativeToken(price.info)) {
        coins.push({
          denom: (price.info as NativeToken).native_token.denom,
          amount
        });
      }
    }
    return (isNativeToken(price.info)) ? this.execute(msg, coins) : this.snip20Send((price.info as Token).token.contract_addr, msg, amount);
  }

  public async storeBuyItem(item: ItemDescription, cid: string, filesystem: FileSystem, password?: string) {
    const amount = (Number(item.price.amount) + Number(item.collateral)).toString();
    const msg = {
      store_buy_item: {
        identifier: {
          filesystem,
          id: cid,
        },
        content_manager: this.CONTENT_MANAGER,
        password,
      }
    };

    return (isNativeToken(item.price.info)) ? this.execute(msg, [{
      denom: (item.price.info as NativeToken).native_token.denom,
      amount
    }]) : this.snip20Send((item.price.info as Token).token.contract_addr, msg, amount);
  }

  public async storeReturnUnapprovedItem(cid: string, filesystem: FileSystem) {
    return this.execute({
      store_return_unapproved_item: {
        identifier: {
          filesystem,
          id: cid,
        }
      }
    });
  }

  public async storeCompleteTx(cid: string, filesystem: FileSystem, rating: number) {
    return this.execute({
      store_complete_tx: {
        identifier: {
          filesystem,
          id: cid,
        },
        rating: rating.toString(),
      }
    }, [], {
      amount: [],
      gas: '600000',
    });
  }

  public async storeDisputeTx(cid: string, filesystem: FileSystem) {
    return this.execute({
      store_dispute_tx: {
        identifier: {
          filesystem,
          id: cid,
        }
      }
    });
  }

  public async storeApproveTx(cid: string, filesystem: FileSystem, buyer: string) {
    return this.execute({
      store_approve_tx: {
        identifier: {
          filesystem,
          id: cid,
        },
        buyer,
      }
    }, [], {
      amount: [],
      gas: '500000',
    });
  }

  public async storeRequestVerification(cid: string, filesystem: FileSystem) {
    return this.execute({
      store_request_verification: {
        identifier: {
          filesystem,
          id: cid,
        },
      }
    });
  }

  public async storeDelistItem(cid: string, filesystem: FileSystem) {
    return this.execute({
      store_delist_item: {
        identifier: {
          filesystem,
          id: cid,
        },
      }
    });
  }

  public async redeemAllBalances() {
    return this.execute({
      redeem_all_balances: {}
    });
  }

  public async curatorsResolveDispute(identifier: Identifier, buyer: Address, delist: boolean) {
    return this.execute({
      curators_resolve_dispute: {
        identifier,
        buyer,
        delist,
        // refund_part // TODO
      }
    }, [], {
      amount: [],
      gas: '600000',
    });
  }

  public async curatorsResolveRequest(identifier: Identifier, delist: boolean) {
    return this.execute({
      curators_resolve_request: {
        identifier,
        delist,
      }
    });
  }

  public async stake(amount: Uint128) {
    return this.snip20SendStaking(this.DAVA_TOKEN, {
      stake: {},
    }, amount);
  }

  public async unstake(amount: Uint128) {
    return this.snip20SendStaking(this.xDAVA_TOKEN, {
      unstake: {},
    }, amount);
  }

  public async claim() {
    return this.executeStakingContract({
      claim: {},
    });
  }

  /**
   * Queries
   */

  private async getConfig(): Promise<ConfigInfoGetAnswer> {
    const queryMsg: QueryMsg = {
      config_get: {}
    };
    return this.query(queryMsg);
  }

  public async storeGetSellerItems(page: number, page_size: number): Promise<StoreGetSellerItemsAnswer> {
    const queryMsg: QueryMsg = {
      store_get_seller_items: {
        page,
        page_size,
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async storeGetBuyerTxs(page: number, page_size: number): Promise<StoreGetBuyerTxsAnswer> {
    const queryMsg: QueryMsg = {
      store_get_buyer_txs: {
        page,
        page_size,
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async storeGetBuyerTx(cid: string, filesystem: FileSystem): Promise<StoreGetBuyerTxAnswer> {
    const queryMsg: QueryMsg = {
      store_get_buyer_tx: {
        identifier: {
          id: cid,
          filesystem
        }
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async storeGetSellerTxs(page: number, page_size: number): Promise<StoreGetSellerTxsAnswer> {
    const queryMsg: QueryMsg = {
      store_get_seller_txs: {
        page,
        page_size,
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async storeDescribeItem(cid: string, filesystem: FileSystem): Promise<StoreDescribeItemAnswer> {
    const queryMsg: QueryMsg = {
      store_describe_item: {
        identifier: {
          filesystem,
          id: cid,
        }
      }
    };
    return this.query(queryMsg);
  }

  public async storeGetItem(cid: string, filesystem: FileSystem): Promise<StoreGetItemAnswer> {
    const queryMsg: QueryMsg = {
      store_get_item: {
        identifier: {
          filesystem,
          id: cid,
        }
      }
    };
    return this.queryPermit(queryMsg);
  }

  private async queryBandOracleRate(assetInfo: ValueInfo): Promise<number> {
    const assetInfoKey = isNativeToken(assetInfo) ? (assetInfo as NativeToken).native_token.denom : (assetInfo as Token).token.contract_addr;
    if (this.oracleRates.has(assetInfoKey)) {
      return this.oracleRates.get(assetInfoKey);
    }
    const res = await this.secretjs?.queryContractSmart(this.PRICING_ORACLE, {
      get_reference_data: {
        base_symbol: getValueName(assetInfo),
        quote_symbol: this.config.ConfigInfoGet.pricing_oracle.base_asset
      }
    }, undefined, this.PRICING_ORACLE_HASH);
    const rate = Number(res.rate) / Number(this.PRICING_ORACLE_RATE_FRACTIONAL);
    this.oracleRates.set(assetInfoKey, rate);
    return rate;
  }

  public async balanceGet(): Promise<BalanceGetAnswer> {
    const queryMsg: QueryMsg = {
      balance_get: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async balanceGetPending(): Promise<BalanceGetPendingAnswer> {
    const queryMsg: QueryMsg = {
      balance_get_pending: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async getPersonalStats(): Promise<GetPersonalStatsAnswer> {
    const queryMsg: QueryMsg = {
      get_personal_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async storeGetStats(): Promise<StoreGetStatsAnswer> {
    const queryMsg: QueryMsg = {
      store_get_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async txGetBuyerStats(): Promise<TxGetBuyerStatsAnswer> {
    const queryMsg: QueryMsg = {
      tx_get_buyer_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async txGetSellerStats(): Promise<TxGetSellerStatsAnswer> {
    const queryMsg: QueryMsg = {
      tx_get_seller_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async curatorsGetDisputes(page: number, page_size: number): Promise<CuratorsGetDisputesAnswer> {
    const queryMsg: QueryMsg = {
      curators_get_disputes: {
        page,
        page_size,
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async curatorsGetDisputeRequests(page: number, page_size: number): Promise<CuratorsGetDisputeRequestsAnswer> {
    const queryMsg: QueryMsg = {
      curators_get_dispute_requests: {
        page,
        page_size,
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async curatorsGetItem(cid: string, filesystem: FileSystem): Promise<CuratorsGetItemAnswer> {
    const queryMsg: QueryMsg = {
      curators_get_item: {
        identifier: {
          id: cid,
          filesystem
        }
      }
    };
    return this.queryPermit(queryMsg);
  }

  public async contentManagerGet(): Promise<ContentManagerGetAnswer> {
    const queryMsg: QueryMsg = {
      content_manager_get: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async stakingConfig(): Promise<Staking.Config> {
    const queryMsg: Staking.QueryMsg = {
      config: {},
    };
    return this.queryStakingContract(queryMsg);
  }

  public async stakingInfo(): Promise<Staking.StakingInfo> {
    const queryMsg: Staking.QueryMsg = {
      staking_info: {},
    };
    return this.queryStakingContract(queryMsg);
  }

  public async getStake(): Promise<Staking.Stake> {
    const queryMsg: Staking.QueryMsg = {
      stake: {
        address: this.defaultAccount.address
      },
    };
    return this.queryStakingContract(queryMsg);
  }

  public async stakingVestingPositions(): Promise<Staking.VestingPositionInfo[]> {
    const queryMsg: Staking.QueryMsg = {
      vesting_positions: {
        address: this.defaultAccount.address
      },
    };
    return this.queryStakingContract(queryMsg);
  }

  public async adminGetStoreStats(): Promise<AdminGetStoreStatsAnswer> {
    const queryMsg: QueryMsg = {
      admin_get_store_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async adminGetTxStats(): Promise<AdminGetTxStatsAnswer> {
    const queryMsg: QueryMsg = {
      admin_get_tx_stats: {}
    };
    return this.queryPermit(queryMsg);
  }

  public async getDavaBalance(): Promise<Uint128> {
    return this.getTokenBalance(this.DAVA_TOKEN);
  }

  public async getTokenBalance(token: Address): Promise<Uint128> {
    await window.keplr.suggestToken(this.CHAIN_ID, token);
    const key = await window.keplr.getSecret20ViewingKey(this.CHAIN_ID, token);

    return (await this.secretjs.queryContractSmart(token, { balance: { address: this.defaultAccount.address, key } })).balance?.amount;
  }

  /**
   * Helpers
   */

  public async getMinBasePriceConverted(priceInfo: ValueInfo): Promise<number> {
    const priceDecimals = getValueDecimals(priceInfo);
    const basePriceRate = await this.queryBandOracleRate(priceInfo);
    const baseAssetDecimals = this.config.ConfigInfoGet.pricing_oracle.base_asset_decimals;

    const minBasePriceConverted = (Number(this.config.ConfigInfoGet.min_base_price) * 10 ** (priceDecimals - baseAssetDecimals)) / basePriceRate;
    const minBasePriceConvertedNominal = minBasePriceConverted / 10 ** baseAssetDecimals; // Returns nominated for further comparison
    return minBasePriceConvertedNominal;
  }

  public async calculateCollateral(price: Value): Promise<number> {
    const priceDecimals = getValueDecimals(price.info);
    const basePriceRate = await this.queryBandOracleRate(price.info);
    const baseAssetDecimals = this.config.ConfigInfoGet.pricing_oracle.base_asset_decimals;

    const min = (Number(this.config.ConfigInfoGet.item_collateral.min) * 10 ** (priceDecimals - baseAssetDecimals)) / basePriceRate;
    const max = (Number(this.config.ConfigInfoGet.item_collateral.max) * 10 ** (priceDecimals - baseAssetDecimals)) / basePriceRate;
    const calculatedCollateral = Number(price.amount) * Number(this.config.ConfigInfoGet.item_collateral.percent);
    return Math.floor(Math.max(Math.min(calculatedCollateral, max), min));
  }
}

export default new DataVault();
