import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@material-ui/core';
import useSettings from '../hooks/useSettings';
import gtm from '../lib/gtm';
import Inventory from 'src/components/dashboard/customer/Inventory';

const Items: FC = () => {
  const { settings } = useSettings();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>SecretBazaar | Inventory</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          py: 8,
          opacity: '0.98'
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Box sx={{ mt: 3 }}>
            <Inventory />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Items;
