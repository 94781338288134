import { lazy, Suspense } from 'react';
import type { PartialRouteObject } from 'react-router';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Items from './pages/Items';
import Disputes from './pages/Disputes';
import Statistics from './pages/Statistics';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Upload = Loadable(lazy(() => import('./pages/Upload')));

const Sell = Loadable(lazy(() => import('./pages/Sell')));
const Buy = Loadable(lazy(() => import('./pages/Buy')));
const Staking = Loadable(lazy(() => import('./pages/Staking')));
const Item = Loadable(lazy(() => import('./pages/Item')));
const Overview = Loadable(lazy(() => import('./pages/Overview')));

const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const Contact = Loadable(lazy(() => import('./pages/Contact')));

const routes: PartialRouteObject[] = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: 'upload',
        element: <Upload />,
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'items',
        children: [
          {
            path: '/',
            element: <Items />
          }
        ]
      },
      {
        path: 'sold',
        children: [
          {
            path: '/',
            element: <Sell />
          }
        ]
      },
      {
        path: 'purchases',
        children: [
          {
            path: '/',
            element: <Buy />
          }
        ]
      },
      {
        path: 'item',
        children: [
          {
            path: ':filesystem/:itemId',
            element: <Item />
          }
        ]
      },
      {
        path: 'staking',
        element: <Staking />
      },
      {
        path: 'statistics',
        element: <Statistics />
      },
      {
        path: 'disputes',
        element: <Disputes />
      },
      {
        path: '*',
        element: <MainLayout />,
        children: [
          {
            path: '404',
            element: <NotFound />
          },
          {
            path: '500',
            element: <ServerError />
          },
          {
            path: '*',
            element: <NotFound />
          }
        ]
      }
    ]
  }
];

export default routes;
