import { FC, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, AppBarProps, Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../../icons/Menu';
// import AccountPopover from './AccountPopover';
// import ContactsPopover from './ContactsPopover';
// import ContentSearch from './ContentSearch';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
// import useSettings from 'src/hooks/useSettings';
import Moon from 'src/icons/Moon';
import useSettings from 'src/hooks/useSettings';
import Sun from 'src/icons/Sun';
import dataVault from 'src/lib/DataVault';
import useMounted from 'src/hooks/useMounted';

const getValues = (settings) => (
  {
    compact: settings.compact,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    magic: settings.magic,
  }
);

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const mounted = useMounted();
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  const [davaPrice, setDavaPrice] = useState<string | null>(null);

  const getDavaPrice = useCallback(async () => {
    await dataVault.waitForConnection();
    if (dataVault.getDavaBalance()) {
      const price = dataVault.getDavaPrice();
      setDavaPrice(price);
    }
  }, [mounted]);

  useEffect(() => {
    getDavaPrice();
  }, [getDavaPrice]);

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const { onSidebarMobileOpen, ...other } = props;

  const toggleTheme = () => {
    const newValues = {
      ...values,
      theme: (settings.theme === 'DARK') ? 'LIGHT' : 'DARK'
    };
    setValues(newValues);
    saveSettings(newValues);
  };

  const toggleMagic = () => {
    const newValues = {
      ...values,
      magic: !settings.magic
    };
    setValues(newValues);
    saveSettings(newValues);
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={toggleMagic}
        >
          <img
            src="/static/logo.png"
            alt="Logo"
            height="58px"
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        {davaPrice
          && (
            <Box sx={{ mr: 1 }}>
              <Typography>
                DAVA:
                &nbsp;
                {davaPrice}
              </Typography>
            </Box>
          )}
        {settings.theme === 'DARK'
          ? (
            <Sun
              sx={{ cursor: 'pointer' }}
              onClick={toggleTheme}
            />
          ) : (
            <Moon
              sx={{ cursor: 'pointer' }}
              onClick={toggleTheme}
            />
          )}
        {/* <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box> */}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
