interface TokenDescription {
  name: string,
  decimals: number,
}

const ValueTokens: { [key: string]: TokenDescription; } = {
  uscrt: { name: 'SCRT', decimals: 6 },
  // secret1sczduxnjahvp0p8azn2pxm6r3e6ytdnvwxan23: { name: 'DAVA', decimals: 6 },
  // secret1slwn85l5qr90u6mcs7r6252dgevlsxyw5atu0c: { name: 'SBZ', decimals: 6 }
};
export default ValueTokens;
