import { Coin } from 'secretjs/types/types';
import { NamedValue, NativeToken, Token, Uint128, Value, ValueInfo } from '../contract-types/Value';
import ValueTokens from './ValueTokens';
import dataVault from './DataVault';

export const getSpecificIcon = (name: string): string => `/static/icons/${name.toLowerCase()}.svg`;

export const getTokenIcon = (namedValue: NamedValue): string => getSpecificIcon(namedValue.name);

export const isNativeToken = (valueInfo: ValueInfo): boolean => Object.prototype.hasOwnProperty.call(valueInfo, 'native_token');

export const getTokenID = (value: Value): string => (isNativeToken(value.info) ? (value.info as NativeToken).native_token.denom : (value.info as Token).token.contract_addr);

export const getValueName = (valueInfo: ValueInfo): string => (isNativeToken(valueInfo) ? ValueTokens[(valueInfo as NativeToken).native_token.denom].name : ValueTokens[(valueInfo as Token).token.contract_addr].name);

export const getValueDecimals = (valueInfo: ValueInfo): number => (isNativeToken(valueInfo) ? ValueTokens[(valueInfo as NativeToken).native_token.denom].decimals : ValueTokens[(valueInfo as Token).token.contract_addr].decimals);

export const valuesToNamed = (value: Value): NamedValue => ({
  name: getValueName(value.info),
  value
});

export const collateralToValue = (collateral: Uint128, value: Value): Value => {
  const colvalue = { ...value };
  colvalue.amount = collateral;
  return colvalue;
};

export const nominalAmount = (value: Value): Uint128 => (Number(value.amount) / Number(10 ** getValueDecimals(value.info))).toFixed(2).toString();

export const nominalBasePriceAmount = (amount: Uint128, decimals: number): Uint128 => (Number(amount) / Number(10 ** decimals)).toFixed(2).toString();

export const nominalDava = (amount: Uint128): Uint128 => {
  const num = (Number(amount) / Number(10 ** dataVault.DAVA_DECIMALS));
  return num < 0.1 ? `${amount} $uDAVA` : `${num.toFixed(2).toString()} $DAVA`;
};

export const nominalDavaAmount = (amount: Uint128): Uint128 => {
  const num = (Number(amount) / Number(10 ** dataVault.DAVA_DECIMALS));
  return num < 0.1 ? amount : num.toFixed(2).toString();
};

export const nominalDavaLabel = (amount: Uint128): Uint128 => {
  const num = (Number(amount) / Number(10 ** dataVault.DAVA_DECIMALS));
  return num < 0.1 ? '$uDAVA' : '$DAVA';
};

export const coinToValue = ({ denom, amount }: Coin): Value => ({ amount, info: { native_token: { denom } } });
